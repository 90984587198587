import React, { useState } from 'react';
import {
    Button, Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem
} from '@nextui-org/react';
import './App.css';
import "./header.css"
import logoo from "./logoo.png";
import { Link } from "react-router-dom";

function Tts() {
    const [inputText, setInputText] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState('Choose Speaker');
    const [charCount, setCharCount] = useState(0);

    const handleChange = (event) => {
        setInputText(event.target.value);
        setCharCount(event.target.value.length);
    };

    const handleSubmit = async (event) => {
        setAudioUrl('');
        setIsLoading(true); // Start loading
        event.preventDefault();
        console.log('Input submitted:', inputText);
        // Remove or comment out setInputText(''); to keep the text in the textarea

        const response = await fetch('http://localhost:5000/run', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ input: inputText, speaker: selectedItem }),
        });

        if (response.ok) {
            const audioData = await response.blob();
            const audioBlob = new Blob([audioData], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
        } else {
            console.error('Error generating audio');
        }
        setIsLoading(false); // End loading
    };

    const handleSelect = (item) => {
        setSelectedItem(item); // Update the selected item state
    };

    return (
        <div>
            <nav className='header'>
                <Link to="/" className="nav-link">
                    <img className="logoo" src={logoo} alt="Logo" />
                </Link>
                <div className="header-nav">
                    <Link to="/" className="nav-link">Home</Link>
                    <Link to="/tts" className="nav-link">TTS</Link>
                    <Link to="/donate" className="nav-link">Donate</Link>
                    <Link to="/about" className="nav-link">About</Link>
                </div>
            </nav>

            <div className="container">
                <div className="typewriter-container"> 
                    <h1 className="typing-effect">Write what you want to hear in the textbox below.</h1></div>
                <p>Please select a speaker and click on the submit button once you have written what you want to hear.</p><br></br>
                
                <form onSubmit={handleSubmit}>
                    <label>
                        <textarea
                            className="input-textarea"
                            value={inputText}
                            onChange={handleChange}
                            placeholder="Enter text here"
                            rows="4"
                        ></textarea>
                    </label>
                    <div className="controls-container">
                        <Dropdown>
                            <DropdownTrigger>
                                <Button variant="bordered" css={{ borderRadius: '20px', background: 'transparent', border: '1px solid rgba(255, 255, 255, 0.6)' }}>{selectedItem}
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Static Actions">
                                <DropdownItem key="alloy" onClick={() => handleSelect('Alloy')}>Alloy</DropdownItem>
                                <DropdownItem key="echo" onClick={() => handleSelect('Echo')}>Echo</DropdownItem>
                                <DropdownItem key="fable" onClick={() => handleSelect('Fable')}>Fable</DropdownItem>
                                <DropdownItem key="onyx" onClick={() => handleSelect('Onyx')}>Onyx</DropdownItem>
                                <DropdownItem key="nova" onClick={() => handleSelect('Nova')}>Nova</DropdownItem>
                                <DropdownItem key="shimmer" onClick={() => handleSelect('Shimmer')}>Shimmer</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <div className="submit-button-container">
                            <p className={charCount > 4000 ? 'char-limit-exceeded' : ''}>
                                {charCount} / 4000
                            </p>
                            <Button type="submit" className="submit-button">Submit</Button>
                        </div>
                    </div>
                </form>
                {audioUrl && (
                    <div className="audio-player">
                        <audio controls src={audioUrl} />
                    </div>
                )}
                {isLoading && <div className="loading-indicator"></div>}
            </div>
        </div>
    );
}

export default Tts;
