import React from 'react';
import './App.css';
import "./header.css"
import logoo from "./logoo.png";
import { Link } from "react-router-dom";

function About() {
    return (
        <div>
            <nav className='header'>
                <Link to="/" className="nav-link">
                    <img className="logoo" src={logoo} alt="Logo" />
                </Link>
                <div className="header-nav">
                    <Link to="/" className="nav-link">Home</Link>
                    <Link to="/tts" className="nav-link">TTS</Link>
                    <Link to="/donate" className="nav-link">Donate</Link>
                    <Link to="/about" className="nav-link">About</Link>
                </div>
            </nav>

            <div className="container">
                <h1>About Us</h1>
                <p>We're a group of four Marianopolis students, and we created this for the students who struggle with written media<br /></p>
                <p>This project was made for those people, so that they could interact with textbooks in a more efficent manner<br /></p>
                <p>The project was also made to make OpenAI accessable for all.<br /></p>
                <p>Since their software can only be used through their API, we decided to make a website so anyone can use it.<br /></p>
            </div>
        </div>
    );
}

export default About;