import React from 'react';
import './App.css';
import "./header.css"
import logoo from "./logoo.png";
import { Link } from "react-router-dom";

function Donate() {
    return (
        <div>
            <nav className='header'>
                <Link to="/" className="nav-link">
                    <img className="logoo" src={logoo} alt="Logo" />
                </Link>
                <div className="header-nav">
                    <Link to="/" className="nav-link">Home</Link>
                    <Link to="/tts" className="nav-link">TTS</Link>
                    <Link to="/donate" className="nav-link">Donate</Link>
                    <Link to="/about" className="nav-link">About</Link>
                </div>
            </nav>

            <div className="container">
                <h1>Donate</h1>
                <p> We utilize OpenAI's text to speech models, so using this website actually costs us money! <br /><br /> We would greatly appreciate any money that you would be willing to donate to keep this service free. <br/><br/> (This page is currently under construction. If you would like to donate, please message one of the owners.) </p>
            </div>
        </div>
    );
}

export default Donate;