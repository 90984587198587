import React from 'react';
import './App.css';
import "./header.css"
import logoo from "./logoo.png"
import About from "./about.js"
import Donate from "./donate.js"
import Tts from "./tts.js"
import {Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <div>
          <nav className='header'>
            <Link to="/" className="nav-link">
              <img className="logoo" src={logoo} alt="Logo" />
            </Link>
            <div className="header-nav">
              <Link to="/" className="nav-link">Home</Link>
              <Link to="/tts" className="nav-link">TTS</Link>
              <Link to="/donate" className="nav-link">Donate</Link>
              <Link to="/about" className="nav-link">About</Link>
            </div>
          </nav>

          <div className="container">
            <hh1><span>Nex</span><span>T</span>TS</hh1>
            <p>Welcome to our project!</p><br></br>
          </div>
        </div>
      } />
      <Route path="/tts" element={<Tts />} />
      <Route path="/donate" element={<Donate />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
}

export default App;

